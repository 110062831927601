import React, { useEffect } from 'react';
import './Shop.less';


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/shop/info_1.png';
import qrcodeImg from '../../assets/shop/qrcode.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const ShopPage: React.FC = () => {

 const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden"); 
        }
    }, [control, inView]);


    return (
        <div className="shopPage">
            <div className="shopPage_info">
                <img  src={infoImg_1} alt="" className="shopPage_info-img"/>
                <div className="shopPage_title">
                    <div className="shopPage_title-cn">线下实体店</div>
                    <div className="shopPage_title-en">OFF-LINE STORE</div>
                </div>
                <div className="shopPage_line"></div>
            </div>
            <div className="shopPage_foot">
                <div className="shopPage_shops">
                 <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">深圳</div>
                             <div className="shopPage_shopsLeft-en">SHEN ZHEN</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳卓悦汇</div>
                                 <div className="shopPage_shopItem-address">深圳市福田区卓悦汇商场B1楼B1-21</div>
                             </div>
                              <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳星河COCO Park</div>
                                 <div className="shopPage_shopItem-address">深圳市福田区星河COCO Park北区B1-J06-J07</div>
                             </div>
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">深圳茂业百货</div>
                                 <div className="shopPage_shopItem-address">深圳市南山区茂业百货1楼F01028X</div>
                             </div>
                         </div>
                     </div>
                    </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >

<div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">郑州</div>
                             <div className="shopPage_shopsLeft-en">ZHENG ZHOU</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">郑州正弘城</div>
                                 <div className="shopPage_shopItem-address">郑州市金水区正弘城LB-M10</div>
                             </div>
                         </div>
                     </div>
                       <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">杭州</div>
                             <div className="shopPage_shopsLeft-en">HANG ZHOU</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">杭州银泰百货</div>
                                 <div className="shopPage_shopItem-address">杭州市上城区银泰百货(西湖店)一楼1B-1504</div>
                             </div>
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">杭州湖滨银泰in77</div>
                                 <div className="shopPage_shopItem-address">杭州市上城区湖滨银泰in77 E区2楼</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">武汉</div>
                             <div className="shopPage_shopsLeft-en">WU HAN</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">武汉万象城</div>
                                 <div className="shopPage_shopItem-address">武汉市江岸区万象城B140铺</div>
                             </div>
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">武汉亲橙万象汇</div>
                                 <div className="shopPage_shopItem-address">武汉市武昌区亲橙万象L1楼142铺</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">长沙</div>
                             <div className="shopPage_shopsLeft-en">CHANG SHA</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">长沙万象城</div>
                                 <div className="shopPage_shopItem-address">长沙市岳麓区万象城LG层G196号铺</div>
                             </div>
                         </div>
                     </div>
                      <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">无锡</div>
                             <div className="shopPage_shopsLeft-en">WU XI</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">无锡万象城</div>
                                 <div className="shopPage_shopItem-address">无锡市滨湖去万象城L117A铺</div>
                             </div>
                         </div>
                     </div>
                     <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">西安</div>
                             <div className="shopPage_shopsLeft-en">XI AN</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">西安CCBD万象城</div>
                                 <div className="shopPage_shopItem-address">西安市雁塔区CCBD万象城B1-15</div>
                             </div>
                         </div>
                     </div>
                     <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             <div className="shopPage_shopsLeft-cn">上海</div>
                             <div className="shopPage_shopsLeft-en">SHANG HAI</div>
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_shopItem">
                                 <div className="shopPage_shopItem-name">上海环球港店</div>
                                 <div className="shopPage_shopItem-address">上海市普陀区环球港店1楼-L1100</div>
                             </div>
                         </div>
                     </div>
                      </motion.div>
                        <div className="shopPage_shopsRow">
                         <div className="shopPage_shopsLeft">
                             &nbsp;
                         </div>
                         <div className="shopPage_shopsRight">
                             <div className="shopPage_qrcode">
                                 <img  src={qrcodeImg} alt="" className="shopPage_qrcode-img"/>
                                 <div className="shopPage_qrcode-text">更多门店扫码开启 </div>
                             </div>
                         </div>
                     </div>
                </div>
            </div>
        </div>

    )
}

export default ShopPage;